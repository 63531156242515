import React, { useState, useEffect, useContext } from "react";
import Table from "./Table";
import Items from "./Items";
import ModalContainer from "./Modal";
import axios from "axios";
import "./Workspace.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Icon } from "../images/Spinner.svg";
import {
	token as tokenAtom,
	author as authorAtom,
	uploadModal as uploadModalAtom,
	itemCount as itemCountAtom,
} from "./Atoms";
import { useRecoilState } from "recoil";
import Log from "./Log";
import { Auth } from "aws-amplify";

/************************************************************************************/

//Material UI code for tabs

export const LoadingContext = React.createContext();
export const SubmitContext = React.createContext();
export const CloudFront = React.createContext();

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const StyledTabs = withStyles({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > div": {
			width: "100%",
			backgroundColor: "#efb758",
		},
	},
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		color: "#fff",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(0),
		"&:focus": {
			opacity: 1,
		},
	},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		padding: theme.spacing(0),
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
	demo2: {
		backgroundColor: "#000",
	},
}));

/*****************************************************************************/

const Workspace = ({ search }) => {
	const classes = useStyles();

	const [token, setToken] = useRecoilState(tokenAtom);

	// Authorization Header

	const [uploadModal, setUploadModal] = useRecoilState(uploadModalAtom);
	const [itemCount, setItemCount] = useRecoilState(itemCountAtom);
	//State for managing the photo upload modal

	const [open, setOpen] = useState(false);

	const handleOpen = (rowData) => {
		setSelected(rowData);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	/***********************************************************************/

	//Handles tab state

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const [styles, setStyles] = useState([]);

	//Fetching style data for Items tab and storing in state
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([]);

	//Captured state holds array of items that have already been captured, displayed as Captured or Not Captured
	//on Picture Status in table
	const [captured, setCaptured] = useState([]);

	const [submitting, setSubmitting] = useState(false);

	const [filteredStyles, setFilteredStyles] = useState([]);

	/********************************************************************** */

	let capturedArray = [];

	useEffect(() => {
		async function getCaptured() {
			const user = await Auth.currentAuthenticatedUser();
			const idToken = user.signInUserSession.idToken.jwtToken;
			const response = await axios.get(
				`${process.env.REACT_APP_ENDPOINT}captured`,
				{
					headers: {
						Authorization: idToken,
					},
				}
			);
			response.data.forEach((item) => {
				capturedArray.push(item.style_id);
			});
			setCaptured(capturedArray);
			console.log(capturedArray);
		}
		getCaptured();
	}, []);

	async function updateStyles() {
		const user = await Auth.currentAuthenticatedUser();
		const idToken = user.signInUserSession.idToken.jwtToken;
		const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}styles`, {
			headers: {
				Authorization: idToken,
			},
		});
		console.log(res.data);
		if (res.data.length > 0) {
			setStyles(res.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	}

	useEffect(() => {
		updateStyles();
	}, []);

	// useEffect(() => {
	// 	async function getHistory() {
	// 		const user = await Auth.currentAuthenticatedUser();
	// 		const idToken = user.signInUserSession.idToken.jwtToken;
	// 		const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}history`, {
	// 			headers: {
	// 				Authorization: idToken,
	// 			},
	// 		});

	// 		console.log(response.data);
	// 		if (response?.data.length > 0) {

	// 			setItems(response.data);
	// 			setItemCount(response.data.length);
	// 		}
	// 	}
	// 	getHistory();
	// }, []);

	//State for currently selected item

	const [selected, setSelected] = useState("");

	const handleSelection = (rowData) => {
		setSelected(rowData);
	};

	function filterCaptured() {
		setLoading(true);
		let intersect = styles.filter((x) =>
			captured.includes(x.style_id.toString())
		);
		let filtered = styles.filter((j) => {
			return !intersect.some((test) => {
				return test.style_id === j.style_id;
			});
		});
		setStyles(filtered);
		setLoading(false);
	}

	function filterItems() {
		setLoading(true);
		let filtered = items.filter((i) => i.image_captured === "N");
		console.log(filtered.length);
		setItems(filtered);
		setLoading(false);
	}

	/*********************************************** */

	const [filtering, setFiltering] = useState(false);

	return (
		<div className="workspace">
			{loading ? (
				<Icon />
			) : (
				<div className={classes.demo2}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<StyledTabs
							value={value}
							onChange={handleChange}
							aria-label="styled tabs example"
						>
							{/* <StyledTab label="Worklist" /> */}
							<StyledTab label={`Items (${itemCount})`} />
							<StyledTab label="Log" />
						</StyledTabs>
						{/* <div style={{display: "flex", marginRight: "30px" }}>
								<Button onClick={filterItems} content="Filter Captured" style={{ alignSelf: "center" }} />
							</div> */}
					</div>

					<Typography className={classes.padding} />

					{/* <TabPanel className="tab-panel" value={value} index={0}>
						{!open ? (
							<div className="table-container">
								<Table
									submitting={submitting}
									setSubmitting={setSubmitting}
									handleOpen={handleOpen}
									selected={selected}
									setSelected={setSelected}
									styles={styles}
									captured={captured}
								/>
							</div>
						) : (
							<div className="modal-container">
								<SubmitContext.Provider value={setSubmitting}>
									<ModalContainer
										setCaptured={setCaptured}
										handleClose={handleClose}
										open={open}
										selected={selected}
										type={"style"}
										setLoading={setLoading}
										loading={loading}
									/>
								</SubmitContext.Provider>
							</div>
						)}
					</TabPanel> */}
					<TabPanel className="tab-panel" value={value} index={0}>
						{!open ? (
							<div className="table-container">
								<Items
									items={items}
									handleClose={handleClose}
									open={open}
									setSelected={setSelected}
									selected={selected}
									handleOpen={handleOpen}
								/>
							</div>
						) : (
							// 	<UploadModalContainer
							// 	setCaptured={setCaptured}
							// 	handleClose={handleClose}
							// 	open={uploadModal}
							// 	selected={selected}
							// 	type={"style"}
							// 	setLoading={setLoading}
							// 	loading={loading}
							// />
							<ModalContainer
								setCaptured={setCaptured}
								handleClose={handleClose}
								open={open}
								selected={selected}
								type={"item"}
								setLoading={setLoading}
								loading={loading}
							/>
						)}
					</TabPanel>
					<TabPanel className="tab-panel" value={value} index={1}>
						<div className="table-container">
							<Log />
						</div>
					</TabPanel>
				</div>
			)}
		</div>
	);
};

export default Workspace;
